import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/StickerPayGetHalva"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { Partners as NewPartners } from "../components/PartnersNew"
import { NewFooter } from "../components/NewFooter"
import { IndexPageData } from "../interfaces/pageProps"

import { getIndexPageData } from "../helpers/getIndexPageData"
import { FormPKW } from "../components/FormPKW"
import { ITEMS_GET_HALVA } from "../components/CardWithMaxBenefits/helpers"
import { MoreBenefitsStickerPay } from "../components/MoreBenefitsStickerPay"

export default function Page({ data }: PageProps<IndexPageData>) {
  const { ligalGethalva, isHiddenPageGetHalva } = getIndexPageData(data)
  return (
    <Layout noHeader noFooter noIndex={isHiddenPageGetHalva}>
      <NewHeader
        redirectLink="https://gethalva.ru/"
        redLogoWithSovcombank
        showHint={false}
        hasCTA
      />
      <Banner orderNum="1" />
      <CardWithMaxBenefits items={ITEMS_GET_HALVA} alignTitleLeft orderNum="2" />
      <FormPKW
        title="Оформите стикер Pay"
        subTitle=""
        dataLayerName="shortPersonalForm"
        hasBirth
        orderNum="3"
        fioPlaceHolder="Фамилия Имя Отчество"
        productName="Халва Стикер"
      />
      <NewCalculator darkColor orderNum="4" />
      <MoreBenefitsStickerPay orderNum="5" />
      <NewPartners darkColor orderNum="6" />
      <NewFooter ligal={ligalGethalva} orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      getHalva: page(url: "https://gethalva.ru/platezhnii-stiker-pay/") {
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
